/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Skeleton } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { addDays, subYears } from 'date-fns';
import { Menu } from '~/presentation/components/menu';
import { HeaderSimples, PageHeader } from '~/presentation/components/header';
import { TablePagination } from '~/presentation/components/tablePagination';
import { translator } from '~/presentation/components/i18n';
import { makeReduxDataPagination } from '~/main/factories/usecases/dataPagination/SetDataFactory';
import { iStore } from '~/domain/interfaces/models';
import { SearchListAppointmentHistory } from '~/presentation/components/search-list';
import { FilterClinicalDocs } from '~/presentation/components/filter';
import ListHistoryAppointment, {
  ownProps,
} from '~/presentation/components/ListHistoryAppointment';
import {
  DocumentCross,
  IconHealthyHeart,
  IconPdfWhite,
} from '~/presentation/base/icons';
import FilterAppointmentHistory from '~/presentation/components/filter/FilterAppointmentHistory';
import { Button } from '~/presentation/components/UI';
import { makeRemoteDownloadAppointmentReport } from '~/main/factories/usecases/professional/DownloadAppointmentReportFactory';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { History } from '~/main/routes';
import { makeRemoteDownloadAppointmentReportPdf } from '~/main/factories/usecases/organizer/DownloadAppointmentReportPdf';
import { ConnectAppointment } from '~/presentation/components/ListHistoryAppointment/mapper/MapperListHistoryAppointment';
import { iListAppointment } from '~/presentation/components/ListHistoryAppointment/interface';
import { makeReduxGetAllAppointmentType } from '~/main/factories/usecases/appointmentType/GetAppointmentType';
import { iHistoryFilter } from './interface';
import {
  Body,
  Container,
  HeaderList,
  LockScroll,
  Wrapper,
} from './StyledSchedule';

type LocationState = {
  title: string;
  user: any;
};

const AppointmentHistory: React.FC<ownProps> = ({
  data,
  loading: loadingAppointment,
}) => {
  const defaultStartDate = new Date();
  defaultStartDate.setHours(0, 0, 0, 0);
  const defaultEndDate = addDays(new Date(), 2);
  defaultEndDate.setHours(23, 59, 59, 999);

  const { loading } = useSelector((store: iStore) => store.clinicalDocs);
  const [state, setState] = useState<iListAppointment[]>(data);
  const [filterHistory, setFilterHistory] = useState<iHistoryFilter>({
    begin: defaultStartDate,
    end: defaultEndDate,
    consultantId: -1,
    professional: -1,
    healthUnit: -1,
    orgUf: 'PB',
  } as iHistoryFilter);
  const locationState = useLocation().state as LocationState;

  const { auth } = useSelector((store: iStore) => store);

  const { role, orgId, orgUnitId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );
  const { dataPagination } = useSelector(
    (store: iStore) => store.dataPagination,
  );

  const handleDownload = () => {
    const professionalId = auth.info.professionals?.find(
      item => item.orgUnit.id === orgUnitId,
    )?.id;

    const isTimestampDefault =
      filterHistory?.begin?.toISOString() === defaultStartDate.toISOString() &&
      filterHistory?.end?.toISOString() === defaultEndDate?.toISOString();

    switch (role) {
      case 'PRO':
        makeRemoteDownloadAppointmentReportPdf()
          .download({
            timestamp:
              isTimestampDefault && !filterHistory?.isBeginDirty
                ? undefined
                : {
                    begin:
                      filterHistory?.begin && filterHistory?.isBeginDirty
                        ? filterHistory.begin?.toISOString()
                        : defaultStartDate.toISOString(),
                    end: filterHistory?.end
                      ? filterHistory.end?.toISOString()
                      : defaultEndDate.toISOString(),
                  },
            professional: professionalId,
            // TODO: Enable when state field is also enabled on filter
            // state: filterHistory?.orgUf,
            city:
              filterHistory?.orgCity !== 'DEFAULT'
                ? filterHistory?.orgCity
                : undefined,
            healthUnit:
              filterHistory?.healthUnit && filterHistory?.healthUnit !== -1
                ? Number(filterHistory?.healthUnit)
                : undefined,
            consultant:
              filterHistory?.consultantId && filterHistory?.consultantId !== -1
                ? filterHistory?.consultantId
                : undefined,
            consultantName:
              filterHistory?.consultantId === -1 &&
              filterHistory?.consultant?.length
                ? filterHistory?.consultant
                : undefined,
            service: filterHistory?.service?.length
              ? filterHistory?.service
              : undefined,
            status: filterHistory?.statusIs?.length
              ? filterHistory.statusIs
              : undefined,
            outcome: filterHistory?.outcome?.length
              ? filterHistory.outcome
              : undefined,
          })
          .then(res => {
            const blob = new Blob([res], { type: 'application/pdf' });
            const urlToPdf = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = urlToPdf;
            a.download = 'professional_appointment_report.pdf';
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            AlertMessage({
              type: 'success',
              message: 'Relatório baixado com sucesso',
            });
          })
          .catch(err => {
            console.log('erro: ', err);
          });
        break;
      case 'ORG':
      case 'ADM':
        makeRemoteDownloadAppointmentReportPdf()
          .download({
            timestamp:
              isTimestampDefault && !filterHistory?.isBeginDirty
                ? undefined
                : {
                    begin:
                      filterHistory?.begin && filterHistory?.isBeginDirty
                        ? filterHistory.begin?.toISOString()
                        : defaultStartDate.toISOString(),
                    end: filterHistory?.end
                      ? filterHistory.end?.toISOString()
                      : defaultEndDate.toISOString(),
                  },
            // TODO: Enable when state field is also enabled on filter
            // state: filterHistory?.orgUf,
            city:
              filterHistory?.orgCity !== 'DEFAULT'
                ? filterHistory?.orgCity
                : undefined,
            healthUnit:
              filterHistory?.healthUnit && filterHistory?.healthUnit !== -1
                ? Number(filterHistory?.healthUnit)
                : undefined,
            professional:
              filterHistory?.professional && filterHistory?.professional !== -1
                ? filterHistory?.professional
                : undefined,
            professionalName:
              filterHistory?.professional === -1 &&
              filterHistory?.professionalName?.length
                ? filterHistory?.professionalName
                : undefined,
            service: filterHistory?.service?.length
              ? filterHistory?.service
              : undefined,
            status: filterHistory?.statusIs?.length
              ? filterHistory.statusIs
              : undefined,
            outcome: filterHistory?.outcome?.length
              ? filterHistory.outcome
              : undefined,
          })
          .then(res => {
            const blob = new Blob([res], { type: 'application/pdf' });
            const urlToPdf = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = urlToPdf;
            a.download = 'organizer_appointment_report.pdf';
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            AlertMessage({
              type: 'success',
              message: 'Relatório baixado com sucesso',
            });
          })
          .catch(err => {
            console.log('erro: ', err);
          });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    makeReduxGetAllAppointmentType().list({
      client: 'SAUDEMEET',
    });
  }, []);

  useEffect(() => {
    if (data.length === 0) {
      makeReduxDataPagination().set([]);
    }
    setState(data);
  }, [data]);

  return (
    <Container>
      {/* <MenuDefault /> */}
      <LockScroll>
        <Menu />
        {locationState?.title ? (
          <PageHeader title={translator('Histórico de atendimentos')} />
        ) : (
          <HeaderSimples
            title={translator('Histórico de atendimentos')}
            icon={IconHealthyHeart}
          />
        )}
        <HeaderList>
          {loading ? (
            <Skeleton variant="rect" width="120px" />
          ) : (
            <>
              {state.length > 0 && state !== undefined ? (
                <TablePagination data={state} itemPerPage={10} />
              ) : (
                <div />
              )}
            </>
          )}
          <Wrapper>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '24px',
              }}
            >
              <SearchListAppointmentHistory>
                <FilterAppointmentHistory
                  filter={filterHistory}
                  handleSetFilter={setFilterHistory}
                />
              </SearchListAppointmentHistory>
              {(role === 'PRO' || role === 'ADM' || role === 'ORG') && (
                <Button icon={IconPdfWhite} onClick={() => handleDownload()}>
                  Gerar PDF
                </Button>
              )}
            </div>
          </Wrapper>
        </HeaderList>
      </LockScroll>
      <Body>
        <ListHistoryAppointment
          data={dataPagination}
          loading={loadingAppointment}
        />
      </Body>
    </Container>
  );
};

export default ConnectAppointment(AppointmentHistory);
