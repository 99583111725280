import React, { useState, KeyboardEvent } from 'react';
import { useSelector } from 'react-redux';
import { IconFilter, IconSearch } from '~/presentation/base/icons';
import { Container } from './styles/StyledSearchList';
import { makeReduxGetAllAppointment } from '~/main/factories/usecases/appointment/GetAllAppointmentFactory';
import Dropdown from '../drop-down';

import { iStore } from '~/domain/interfaces/models';
import { translator } from '../i18n';

interface SearchProps {
  name?: string;
  children: JSX.Element[] | JSX.Element;
  icon?: JSX.Element;
}

const SearchListAppointment: React.FC<SearchProps> = ({ children }) => {
  const { auth } = useSelector((store: iStore) => store);

  const [consultant, setConsultant] = useState('');
  const [professional, setProfessional] = useState('');
  const { orgId } = useSelector((store: iStore) => store.auth.selectUser);

  const userType = auth.selectUser.role;

  const consultantId = auth.info.consultants?.find(
    item => item.org?.id === auth.selectUser?.orgId,
  )?.id;

  const professionalId = auth.info.professionals?.find(
    item => item.orgUnit?.id === auth.selectUser?.orgUnitId,
  )?.id;

  const handleSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (userType) {
      case 'PRO':
        if (e.key === 'Enter') {
          makeReduxGetAllAppointment().getAll({
            filter: {
              professional: professionalId,
              consultantName: consultant,
              org: orgId,
            },
          });
        }
        break;
      case 'CON':
        if (e.key === 'Enter') {
          makeReduxGetAllAppointment().getAll({
            filter: {
              consultant: consultantId,
              professionalName: professional,
              org: orgId,
            },
          });
        }
        break;
      default:
        if (e.key === 'Enter') {
          makeReduxGetAllAppointment().getAll({
            filter: {
              professionalName: professional,
              org: orgId,
            },
          });
        }
        break;
    }
  };

  return (
    <Container onSubmit={() => handleSubmit}>
      <IconSearch id="btn_searchFilter" />
      <input
        id="input_search"
        placeholder={`${translator('Buscar por...')}`}
        value={consultant}
        onChange={e => {
          setConsultant(e.target.value);
        }}
        onKeyPressCapture={handleSubmit}
      />
      <Dropdown label="Filtros" icon={<IconFilter />}>
        {children}
      </Dropdown>
    </Container>
  );
};

export default SearchListAppointment;
