import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import _ from 'lodash';
import CreatableSelect from 'react-select/creatable';
import { HeaderSimples } from '~/presentation/components/header';
import { Menu } from '~/presentation/components/menu';
import { Container, Body, Text, Content, Left, selectStyles } from './styles';
import Input from '~/presentation/components/UI/input';
import { Select } from '~/presentation/components/UI';
import HealthUnit from './HealthUnit';
import {
  makeReduxGetAllHealthUnits,
  makeRemoteGetAllHealthUnits,
} from '~/main/factories/usecases/healthUnits/GetAll';
import { makeRemoteGetUfs } from '~/main/factories/usecases/externalServices/GetUfs';
import { makeRemoteGetCitiesByUf } from '~/main/factories/usecases/externalServices/GetCitiesByUf';
import { iHealthUnit, iStore } from '~/domain/interfaces/models';
import { GetCitiesByUf } from '~/domain/usecases/externalServices/remote';
import {
  Control,
  DropdownIndicator,
  Option,
} from '../../components/filter/styles/StyledFilterAppointmentHistory';

const HealthcareFacility: React.FC = () => {
  const [cities, setCities] = React.useState<GetCitiesByUf.Model>([]);
  const [selectedCity, setSelectedCity] = React.useState<number>();
  const { records: healthUnits } = useSelector(
    (store: iStore) => store.healthUnits,
  );

  const [unit, setUnit] = useState<number>();

  useEffect(() => {
    makeRemoteGetCitiesByUf()
      .getCitiesByUf({ uf: 'PB' })
      .then(res => {
        setCities(res);
      });

    makeReduxGetAllHealthUnits().getAll({
      limit: 9999,
    });
  }, []);

  useEffect(() => {
    makeReduxGetAllHealthUnits().getAll({
      limit: 9999,
      city: String(selectedCity),
    });
  }, [selectedCity]);

  const generateHealthUnitSelectOptions = useCallback(() => {
    return _.sortBy(
      healthUnits?.flatMap(value => {
        return [
          {
            label: `${value.cnes} - ${value.name}`,
            value: value.id,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [healthUnits]);

  const healthUnitMultiselectSelected = useMemo(() => {
    return generateHealthUnitSelectOptions().find(
      option => option?.value === unit,
    );
  }, [generateHealthUnitSelectOptions, unit]);

  return (
    <Container>
      <Menu />
      <HeaderSimples title="Escolha uma especialidade" />
      <Body>
        <Content>
          <Left>
            <Text size={16} weight={700}>
              Selecione qual Estabelecimento de Saúde deseja visualizar agora.
            </Text>
            <Text size={16} weight={400}>
              Esses são todos os estabelecimentos cadastrados atualmente.
            </Text>
          </Left>
          <div style={{ display: 'flex', gap: 24, marginTop: 24 }}>
            <Select label="Estado" height="40px" value="PB" disabled>
              <option value="PB">Paraíba</option>
            </Select>
            <Select
              label="Município"
              height="40px"
              onChange={e => {
                setSelectedCity(Number(e.target.value));
                setUnit(undefined);
              }}
              value={selectedCity}
            >
              <option value="">Selecione um município</option>
              {cities.map(city => (
                <option key={city.id} value={city.id}>
                  {city.nome}
                </option>
              ))}
            </Select>
          </div>
          <div style={{ marginTop: 16 }}>
            <p style={{ marginBottom: 4 }}>Unidade de saúde</p>
            <CreatableSelect
              className="select"
              components={{
                Control,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                Option,
              }}
              controlShouldRenderValue
              hideSelectedOptions
              placeholder="ex.: Hospital Albert Einstein"
              formatCreateLabel={(label: string) => `Buscar por ${label}`}
              options={generateHealthUnitSelectOptions()}
              value={healthUnitMultiselectSelected}
              onChange={e => {
                if (!e) return;
                setUnit(e?.value);
              }}
              styles={selectStyles()}
              isValidNewOption={() => false}
              noOptionsMessage={() => 'Nenhum resultado encontrado'}
            />
          </div>
        </Content>
        <div>
          <HealthUnit data={unit} />
        </div>
      </Body>
    </Container>
  );
};

export default HealthcareFacility;
