/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Button } from '~/presentation/components/UI/buttons';
import { Step, StepMagic } from '~/presentation/components/steps';
import { Health } from '~/presentation/base/icons';

import { CreateClinicalDocs as ReduxCreateClinicalDocs } from '~/domain/usecases/clinicalDocs/redux/CreateClinicalDocs';
import { CreateClinicalDocs as RemoteCreateClinicalDocs } from '~/domain/usecases/clinicalDocs/remote/CreateClinicalDocs';

import { History } from '~/routes';

import { makeReduxCreateClinicalDocs } from '~/main/factories/usecases/clinicalDocs/CreateClinicalDocsFactory';

import { schema } from '~/validation/validators/document/CreateClinicalDocValidator';

import { iStore } from '~/domain/interfaces/models';
import { makeRemoteGetClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetClinicalDocsFactory';
import { makeRemoteCreateForeignClinicalDocs } from '~/main/factories/usecases/clinicalDocs/CreateForeignClinicalDocsFactory';
import { CreateForeignClinicalDocs } from '~/domain/usecases/clinicalDocs/remote';
import PatientData from '../PatientDataClinicalDocuments';
import { translator } from '../i18n';
import { ConnectComponent } from './mapper/MapperExamRequest';
import { iCreateExamRequest } from './interface';
import ExamRequestView from './ExamRequestView';
import Observation from './Observation';
import GeneralData, { ownProps as GeneralDataProps } from './GeneralData';
import {
  Container,
  Content,
  Header,
  Body,
  ContainerButton,
} from './styles/StyledExamRequest';
import { Signature } from '../signature';
import { makeRemoteGetAppointmentById } from '~/main/factories/usecases/appointment/GetAppointmentbyIdFactory';

export interface Props {
  examRequest: ReduxCreateClinicalDocs | RemoteCreateClinicalDocs;
}

interface iStateLocation {
  appointmentId: string;
  professional: string;
  consultant: string;
}

export type ownProps = Props & GeneralDataProps;

const ExamRequest: React.FC<ownProps> = props => {
  const [crement, setCrement] = useState<number>(1);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const { state } = useLocation<iStateLocation>();

  const { loading } = useSelector((store: iStore) => store.clinicalDocs);
  const { auth } = useSelector((store: iStore) => store);

  const { role, orgUnitId, orgId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const orgUnitName = orgUnitId
    ? auth.info.professionals?.find(item => item.orgUnit.id === orgUnitId)
        ?.orgUnit.name
    : '';

  const specialty =
    role === 'PRO'
      ? auth.info.professionals?.find(item => item.orgUnit.id === orgUnitId)
          ?.professions.specialties[0].id
      : -1;

  const [exam, setExam] = useState<iCreateExamRequest>({
    professional: state && state.professional ? Number(state.professional) : -1,
    patient: {
      fullname: state && state?.consultant ? state?.consultant : '',
      gender: '',
      age: -1,
    },
    orgUnitName,
  });

  let dataToSend: RemoteCreateClinicalDocs.Params = {};

  const CreateExamRequest = () => {
    // VINCULADO A UM ATENDIMENTO
    if (state && state.appointmentId !== undefined) {
      dataToSend = {
        appointment:
          state && state.appointmentId
            ? Number(state.appointmentId)
            : undefined,

        type: 'EXAMREQUEST',
        content: {
          solicitacao: [
            {
              exam: exam.exam,
            },
          ],
          indicacaoClinica: exam.indicacaoClinica,
        },
        signatureSource: exam?.signatureSource ?? undefined,
        certificate:
          exam?.signatureSource === 'VIDAAS' ? undefined : exam.certificate,
        certificateId:
          exam?.signatureSource === 'VIDAAS' ? undefined : exam.certificateId,
        patient:
          exam.situation === 'NOT-REGISTERED'
            ? {
                fullname: exam?.patient?.fullname!,
                gender: exam?.patient?.gender!,
                age: exam?.patient?.age!,
              }
            : exam?.patient?.id,
      };
      // NÃO VINCULADO A UM ATENDIMENTO
    } else {
      dataToSend = {
        type: 'EXAMREQUEST',
        content: {
          solicitacao: [
            {
              exam: exam.exam,
            },
          ],
          indicacaoClinica: exam.indicacaoClinica,
        },
        org: orgId,
        orgUnit: orgUnitId,
        patient:
          exam.situation === 'NOT-REGISTERED'
            ? {
                fullname: exam?.patient?.fullname!,
                gender: exam?.patient?.gender!,
                age: exam?.patient?.age!,
              }
            : exam?.patient?.id,
        professional:
          state && state.professional
            ? Number(state.professional)
            : exam.professional,
        certificate:
          exam?.signatureSource === 'VIDAAS' ? undefined : exam.certificate,
        certificateId:
          exam?.signatureSource === 'VIDAAS' ? undefined : exam.certificateId,
        specialty,
        signatureSource: exam?.signatureSource ?? undefined,
      };
    }
    try {
      if (exam?.signatureSource === 'VIDAAS') {
        if (exam.situation === 'NOT-REGISTERED') {
          makeRemoteCreateForeignClinicalDocs()
            .createForeign({
              body: {
                type: 3,
                certificate:
                  exam?.signatureSource === 'VIDAAS'
                    ? undefined
                    : exam.signatureSource,
                content: {
                  indicacaoClinica: exam.indicacaoClinica,
                  solicitacao: [
                    {
                      exam: exam.exam!,
                    },
                  ],
                },
                patient: {
                  age: Number(exam?.patient?.age),
                  fullname: exam?.patient?.fullname!,
                  gender: exam?.patient
                    ?.gender as CreateForeignClinicalDocs.Params['body']['patient']['gender'],
                },
                signatureSource: exam.signatureSource ?? undefined,
                org: orgId,
                orgUnit: orgUnitId ?? undefined,
                professional: exam.professional,
                specialty,
              },
            })
            .catch(e => {
              console.log('##error: ', e);
            })
            .then(response => {
              console.log('##response: ', response);
              makeRemoteGetClinicalDocs()
                .get({ docId: Number(response?.id) })
                .then(res => {
                  const url = res?.dataToSign;
                  localStorage.setItem('clinicalDocId', String(response?.id));
                  // window.open(url, '_blank');
                  window.location.href = url;
                })

                .catch(e => console.log(e));
            });
          return;
        }
        makeReduxCreateClinicalDocs().create({
          ...dataToSend,
          signDocWithVidaas: (id: number) => {
            makeRemoteGetClinicalDocs()
              .get({ docId: id })
              .then(res => {
                const url = res?.dataToSign;
                localStorage.setItem('clinicalDocId', String(id));
                // window.open(res?.dataToSign, '_blank');
                window.location.href = url;
              })

              .catch(e => console.log(e));
          },
        });
      } else {
        if (exam.situation === 'NOT-REGISTERED') {
          makeRemoteCreateForeignClinicalDocs()
            .createForeign({
              body: {
                type: 3,
                certificate:
                  exam?.signatureSource === 'VIDAAS'
                    ? undefined
                    : exam.signatureSource,
                content: {
                  indicacaoClinica: exam.indicacaoClinica,
                  solicitacao: [
                    {
                      exam: exam.exam!,
                    },
                  ],
                },
                patient: {
                  age: Number(exam?.patient?.age),
                  fullname: exam?.patient?.fullname!,
                  gender: exam?.patient
                    ?.gender as CreateForeignClinicalDocs.Params['body']['patient']['gender'],
                },
                signatureSource: undefined,
                org: orgId,
                orgUnit: orgUnitId ?? undefined,
                professional: exam.professional,
                specialty,
              },
            })
            .then(res => {
              console.log('##res: ', res);
              History.goBack();
            })
            .catch(err => {
              console.error(err);
            });

          return;
        }

        makeReduxCreateClinicalDocs().create(dataToSend);
      }
    } catch (e) {
      console.error('erro ao enviar para o back', e);
    }
  };

  const data = {
    isSigned: 'Assinado',
    name: 'Danilo',
  };

  function next(dados: iCreateExamRequest) {
    setExam({ ...exam, ...dados });
    handleBar(1);
  }

  function back(dados: iCreateExamRequest) {
    setExam({ ...exam, ...dados });
    handleBar(-1);
  }

  function handleBar(number: number) {
    if (crement + number > 4 || crement + number < 1) {
      setCrement(crement);
    } else {
      setCrement(crement + number);
    }
  }

  useEffect(() => {
    if (state.appointmentId)
      makeRemoteGetAppointmentById()
        .getAppointmentById({
          id: Number(state.appointmentId),
        })
        .then(res => {
          setExam({
            patient: {
              id: res.consultant?.id,
              fullname: res.appointment.info?.fullname,
              gender: res.appointment.info?.sex,
            },
            situation: res.consultant?.id ? 'REGISTERED' : 'NOT-REGISTERED',
          });
        })
        .catch(err => {
          console.error('error: ', err);
        });
  }, [state.appointmentId]);

  console.log('##exam: ', exam);
  return (
    <Container>
      <Content>
        <Health />
        <h1>{`${translator('Requisição de exame')}`}</h1>
      </Content>
      {crement !== 4 ? (
        <Header>
          <StepMagic current={crement}>
            <Step title={`${translator('Informações gerais')}`} />
            <Step title={`${translator('Dados do paciente')}`} />
            <Step title={`${translator('Prescrição')}`} />
            <Step title={`${translator('Assinatura do documento')}`} />
          </StepMagic>
        </Header>
      ) : null}
      <Body>
        {crement === 1 && (
          <GeneralData
            next={next}
            state={exam}
            consultant={props.consultant}
            professional={props.professional}
            disabled={!!state.appointmentId}
          />
        )}
        {crement === 2 && (
          <PatientData
            state={exam}
            consultant={props.consultant}
            next={next}
            back={back}
            disabled={!!state.appointmentId}
          />
        )}
        {crement === 3 && <Observation state={exam} next={next} back={back} />}
        {crement === 4 && (
          <Signature
            changeSelected={(e: {
              certificateId: string;
              certificate: string;
              signatureSource?: string;
            }) =>
              setExam({
                ...exam,
                certificate: e.certificate,
                certificateId: e.certificateId,
                signatureSource: e.signatureSource,
              })
            }
            changeDisabled={(stat: boolean) => setDisableButton(stat)}
            onSignSubmit={CreateExamRequest}
          />
        )}
        {crement === 5 && (
          <ExamRequestView isSigned={data.isSigned} name={data.name} />
        )}
      </Body>
      {crement === 4 ? (
        <ContainerButton>
          <Button
            id="btn_prev_step"
            rounded
            variant="secundary"
            onClick={() => handleBar(-1)}
            size="small"
          >
            {`${translator('Voltar')}`}
          </Button>
          <Button
            id="btn_next_step"
            rounded
            size="small"
            onClick={CreateExamRequest}
            disabled={loading || disableButton}
          >
            {`${translator('Concluir')}`}
          </Button>
        </ContainerButton>
      ) : null}
    </Container>
  );
};

export default ConnectComponent(ExamRequest);
