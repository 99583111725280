/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Step, StepMagic } from '~/presentation/components/steps';
import { Button } from '../UI';
import {
  Container,
  Content,
  Header,
  Body,
  ContainerButton,
} from './styles/StyledMedicalCertificate';
import { Health } from '~/presentation/base/icons';
import Certificate from './Certificate';
import { Signature } from '../signature';
import CertificateView from './CertificateView';

import { CreateClinicalDocs as ReduxCreateClinicalDocs } from '~/domain/usecases/clinicalDocs/redux/CreateClinicalDocs';
import { CreateClinicalDocs as RemoteCreateClinicalDocs } from '~/domain/usecases/clinicalDocs/remote/CreateClinicalDocs';

import { schema } from '~/validation/validators/document/CreateClinicalDocValidator';

import GeneralData, { ownProps as GeneralDataProps } from './GeneralData';

import { ConnectComponent } from './mapper/MapperMedicalCertificate';

import { iCreateMedicalCertificate } from './interface';
import {
  makeRemoteCreateClinicalDocs,
  makeReduxCreateClinicalDocs,
} from '~/main/factories/usecases/clinicalDocs/CreateClinicalDocsFactory';
import { iStore } from '~/domain/interfaces/models';
import storeDev from '~/data/store';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { AlertMessage } from '../messages/AlertMessage';
import { translator } from '../i18n';
import { History } from '~/routes';
import { makeRemoteGetClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetClinicalDocsFactory';
import PatientData from '../PatientDataClinicalDocuments';
import { makeRemoteCreateForeignClinicalDocs } from '~/main/factories/usecases/clinicalDocs/CreateForeignClinicalDocsFactory';
import { CreateForeignClinicalDocs } from '~/domain/usecases/clinicalDocs/remote';
import { makeRemoteGetAppointmentById } from '~/main/factories/usecases/appointment/GetAppointmentbyIdFactory';

export interface Props {
  medicalCertificate: ReduxCreateClinicalDocs | RemoteCreateClinicalDocs;
}

interface iStateParams {
  appointmentId: string;
  professional: string;
  consultant: string;
}

export type ownProps = Props & GeneralDataProps;

const MedicalCertificate: React.FC<ownProps> = props => {
  const [crement, setCrement] = useState<number>(1);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const stateParams = useLocation<iStateParams>().state;
  const { auth } = useSelector((store: iStore) => store);
  const { loading } = useSelector((store: iStore) => store.clinicalDocs);
  const { role, orgUnitId, orgId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const orgUnitName = orgUnitId
    ? auth.info.professionals?.find(item => item.orgUnit.id === orgUnitId)
        ?.orgUnit.name
    : '';

  const professional = auth.info.professionals?.find(
    item => item.orgUnit.id === orgUnitId,
  );

  const [certificate, setCertificate] = useState<iCreateMedicalCertificate>({
    professional:
      stateParams && stateParams.professional
        ? Number(stateParams.professional)
        : -1,
    patient: {
      fullname:
        stateParams && stateParams?.consultant ? stateParams?.consultant : '',
      gender: '',
      age: -1,
    },
    specialty:
      role === 'PRO' ? professional?.professions.specialties[0].id : -1,
    content: {
      periodo: '',
      observacoes: '',
    },
    orgUnitName,
    org: orgId,
    type: 'MEDICALCERTIFICATE',
  });

  const CreateMedicalCertificate = () => {
    // SE TIVER VINCULADO A UM ATENDIMENTO
    if (stateParams && stateParams.appointmentId !== undefined) {
      const dataToSend: RemoteCreateClinicalDocs.Params = {
        appointment: stateParams.appointmentId
          ? Number(stateParams.appointmentId)
          : -1,
        type: 'MEDICALCERTIFICATE',
        content: {
          observacoes: certificate.observacoes,
          periodo: certificate.periodo,
        },
        signatureSource: certificate.signatureSource ?? undefined,
        certificate:
          certificate?.signatureSource === 'VIDAAS'
            ? undefined
            : certificate.certificate,
        certificateId:
          certificate?.signatureSource === 'VIDAAS'
            ? undefined
            : certificate.certificateId,
      };

      try {
        if (certificate.signatureSource === 'VIDAAS') {
          if (certificate.situation === 'NOT-REGISTERED') {
            makeRemoteCreateForeignClinicalDocs()
              .createForeign({
                body: {
                  type: 2,
                  certificate:
                    certificate?.signatureSource === 'VIDAAS'
                      ? undefined
                      : certificate.certificate,
                  content: {
                    observacoes: certificate.observacoes,
                    periodo: certificate.periodo,
                  },
                  patient: {
                    age: Number(certificate?.patient?.age),
                    fullname: certificate?.patient?.fullname!,
                    gender: certificate.patient
                      ?.gender as CreateForeignClinicalDocs.Params['body']['patient']['gender'],
                  },
                  signatureSource: certificate.signatureSource ?? undefined,
                  org: orgId,
                  orgUnit: orgUnitId ?? undefined,
                  professional: certificate.professional,
                  specialty: certificate.specialty,
                },
              })
              .catch(e => {
                console.log('##error: ', e);
              })
              .then(response => {
                console.log('##response: ', response);
                makeRemoteGetClinicalDocs()
                  .get({ docId: Number(response?.id) })
                  .then(res => {
                    const url = res?.dataToSign;
                    localStorage.setItem('clinicalDocId', String(response?.id));
                    // window.open(url, '_blank');
                    window.location.href = url;
                  })

                  .catch(e => console.log(e));
              });
            return;
          }
          makeReduxCreateClinicalDocs().create({
            ...dataToSend,
            signDocWithVidaas: (id: number) => {
              makeRemoteGetClinicalDocs()
                .get({ docId: id })
                .then(res => {
                  const url = res?.dataToSign;
                  localStorage.setItem('clinicalDocId', String(id));
                  // window.open(res?.dataToSign, '_blank');
                  window.location.href = url;
                })
                .catch(e => console.log(e));
            },
          });
        } else {
          makeReduxCreateClinicalDocs().create(dataToSend);
        }
      } catch (e) {
        console.error(e);
      }
      // SE NÃO TIVER VINCULADO A UM ATENDIMENTO
    } else {
      const dataToSend: RemoteCreateClinicalDocs.Params = {
        type: 'MEDICALCERTIFICATE',
        content: {
          observacoes: certificate.observacoes,
          periodo: certificate.periodo,
        },
        org: orgId,
        orgUnit: orgUnitId ?? undefined,
        // patient: {
        //   fullname: certificate.patient.fullname,
        //   gender: certificate.patient.gender,
        //   age: certificate.patient.age,
        // },
        patient:
          certificate.situation === 'NOT-REGISTERED'
            ? {
                fullname: certificate?.patient?.fullname!,
                gender: certificate?.patient?.gender!,
                age: certificate?.patient?.age!,
              }
            : certificate?.patient?.id,
        professional: certificate.professional,
        certificate:
          certificate?.signatureSource === 'VIDAAS'
            ? undefined
            : certificate.certificate,
        certificateId:
          certificate.signatureSource === 'VIDAAS'
            ? undefined
            : certificate.certificateId,
        specialty: certificate.specialty,
        signatureSource: certificate.signatureSource ?? undefined,
      };

      try {
        if (certificate?.signatureSource === 'VIDAAS') {
          if (certificate.situation === 'NOT-REGISTERED') {
            makeRemoteCreateForeignClinicalDocs()
              .createForeign({
                body: {
                  type: 2,
                  certificate:
                    certificate?.signatureSource === 'VIDAAS'
                      ? undefined
                      : certificate.certificate,
                  content: {
                    observacoes: certificate.observacoes,
                    periodo: certificate.periodo,
                  },
                  patient: {
                    age: Number(certificate?.patient?.age),
                    fullname: certificate?.patient?.fullname!,
                    gender: certificate.patient
                      ?.gender as CreateForeignClinicalDocs.Params['body']['patient']['gender'],
                  },
                  signatureSource: certificate.signatureSource ?? undefined,
                  // appointment: stateParams.appointmentId
                  //   ? Number(stateParams.appointmentId)
                  //   : -1,
                  org: orgId,
                  orgUnit: orgUnitId ?? undefined,
                  professional: certificate.professional,
                  specialty: certificate.specialty,
                },
              })
              .catch(e => {
                console.log('##error: ', e);
              })
              .then(response => {
                console.log('##response: ', response);
                makeRemoteGetClinicalDocs()
                  .get({ docId: Number(response?.id) })
                  .then(res => {
                    const url = res?.dataToSign;
                    localStorage.setItem('clinicalDocId', String(response?.id));
                    // window.open(url, '_blank');
                    window.location.href = url;
                  })

                  .catch(e => console.log(e));
              });
            return;
          }
          makeReduxCreateClinicalDocs().create({
            ...dataToSend,
            signDocWithVidaas: (id: number) => {
              makeRemoteGetClinicalDocs()
                .get({ docId: id })
                .then(res => {
                  const url = res?.dataToSign;
                  localStorage.setItem('clinicalDocId', String(id));
                  // window.open(url, '_blank');
                  window.location.href = url;
                })

                .catch(e => console.log(e));
            },
          });
        } else {
          if (certificate.situation === 'NOT-REGISTERED') {
            makeRemoteCreateForeignClinicalDocs()
              .createForeign({
                body: {
                  type: 2,
                  certificate:
                    certificate?.signatureSource === 'VIDAAS'
                      ? undefined
                      : certificate.certificate,
                  content: {
                    observacoes: certificate.observacoes,
                    periodo: certificate.periodo,
                  },
                  patient: {
                    age: Number(certificate?.patient?.age),
                    fullname: certificate?.patient?.fullname!,
                    gender: certificate.patient
                      ?.gender as CreateForeignClinicalDocs.Params['body']['patient']['gender'],
                  },
                  signatureSource: undefined,
                  // appointment: stateParams.appointmentId
                  //   ? Number(stateParams.appointmentId)
                  //   : -1,
                  org: orgId,
                  orgUnit: orgUnitId ?? undefined,
                  professional: certificate.professional,
                  specialty: certificate.specialty,
                },
              })
              .then(res => {
                console.log('##res: ', res);
                storeDev.dispatch({
                  type: MessageOptions.clinicalDocsCreateSuccess,
                });
                History.goBack();
              })
              .catch(err => {
                console.error(err);
              });

            return;
          }

          makeReduxCreateClinicalDocs().create(dataToSend);
          storeDev.dispatch({ type: MessageOptions.clinicalDocsCreateSuccess });
        }
      } catch (e) {
        console.log('create clinical docs error: ', e);
      }
    }
  };

  const data = {
    isSigned: 'Assinado',
    name: 'Denilson',
  };

  function next(dados: iCreateMedicalCertificate) {
    setCertificate({ ...certificate, ...dados });
    handleBar(1);
  }

  function back(dados: iCreateMedicalCertificate) {
    setCertificate({ ...certificate, ...dados });
    handleBar(-1);
  }

  function handleBar(number: number) {
    if (crement + number > 4 || crement + number < 1) {
      setCrement(crement);
    } else {
      setCrement(crement + number);
    }
  }

  useEffect(() => {
    if (stateParams.appointmentId)
      makeRemoteGetAppointmentById()
        .getAppointmentById({
          id: Number(stateParams.appointmentId),
        })
        .then(res => {
          setCertificate({
            birthDate: res.appointment.info?.birthdate,
            patient: {
              id: res.consultant?.id,
              birthDate: res.appointment.info?.birthdate,
              fullname: res.appointment.info?.fullname,
              gender: res.appointment.info?.sex,
            },
            healthUnit: res.healthUnit?.id,
            situation: res.consultant?.id ? 'REGISTERED' : 'NOT-REGISTERED',
          });
        })
        .catch(err => {
          console.error('error: ', err);
        });
  }, [stateParams.appointmentId]);

  return (
    <Container>
      <Content>
        <Health />
        <h1>{`${translator('Atestado Médico')}`}</h1>
      </Content>
      {crement !== 4 ? (
        <Header>
          <StepMagic current={crement}>
            <Step title={`${translator('Informações gerais')}`} />
            <Step title={`${translator('Dados do paciente')}`} />
            <Step title={`${translator('Prescrição')}`} />
            <Step title={`${translator('Assinatura do documento')}`} />
          </StepMagic>
        </Header>
      ) : null}
      <Body>
        {crement === 1 && (
          <GeneralData
            professional={props.professional}
            next={next}
            state={certificate}
            consultant={props.consultant}
            disabled={!!stateParams.appointmentId}
          />
        )}
        {crement === 2 && (
          <PatientData
            state={certificate}
            consultant={props.consultant}
            next={next}
            back={back}
            disabled={!!stateParams.appointmentId}
          />
        )}
        {crement === 3 && (
          <Certificate state={certificate} next={next} back={back} />
        )}
        {crement === 4 && (
          <Signature
            changeSelected={(e: {
              certificateId: string;
              certificate: string;
              signatureSource?: string;
            }) =>
              setCertificate({
                ...certificate,
                certificate: e.certificate,
                certificateId: e.certificateId,
                signatureSource: e.signatureSource,
              })
            }
            changeDisabled={(state: boolean) => setDisableButton(state)}
            onSignSubmit={CreateMedicalCertificate}
          />
        )}
        {crement === 5 && (
          <CertificateView isSigned={data.isSigned} name={data.name} />
        )}
      </Body>
      {crement === 4 ? (
        <ContainerButton>
          <Button
            id="button_back"
            rounded
            variant="secundary"
            onClick={() => handleBar(-1)}
            size="small"
          >
            {`${translator('Voltar')}`}
          </Button>
          <Button
            id="button_conclude"
            rounded
            size="small"
            onClick={CreateMedicalCertificate}
            disabled={loading || disableButton}
          >
            {`${translator('Concluir')}`}
          </Button>
        </ContainerButton>
      ) : null}
    </Container>
  );
};

export default ConnectComponent(MedicalCertificate);
