import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { iProfession, iStore } from '~/domain/interfaces/models';
import { makeReduxSetupProfession } from '~/main/factories/usecases/profession';
import { makeReduxGetAllProfessionals } from '~/main/factories/usecases/professional/GetAllProfessionalFactory';
import { makeReduxGetAllSpecialty } from '~/main/factories/usecases/specialty/GetAllSpecialtyFactory';
import SearchSelect from '~/presentation/components/UI/searchSelect';
import { useRemoteReport } from '~/presentation/hooks/remoteReport';
import { Form, FormRow, SectionContainer, SectionLabel } from '../styles';

export const ResponsibleSection: React.FC = () => {
  const { disableField } = useRemoteReport();

  const { control } = useFormContext();

  const { results: professions, selected } = useSelector(
    (store: iStore) => store.professions,
  );

  const { selectUser } = useSelector((store: iStore) => store.auth);

  const { results: specialty, loading: loadingSpecialty } = useSelector(
    (store: iStore) => store.specialty,
  );

  const { results: professionals } = useSelector(
    (store: iStore) => store.professional,
  );

  const specialties = specialty.map(specialty => ({
    ...specialty,
    id: specialty.specialty?.id,
    name: specialty.specialty?.name,
  }));

  const handleSelectProfession = useCallback(
    (profession: iProfession['results'][0]) => {
      makeReduxSetupProfession().setup({
        professionId: profession.profession.id,
      });

      makeReduxGetAllSpecialty().getAll({
        filter: {
          profession: profession.profession.id,
          hasProfessional: true,
        },
      });
    },
    [],
  );

  return (
    <SectionContainer>
      <SectionLabel>Responsável</SectionLabel>
      <Form>
        <FormRow gap="0.5rem">
          <SearchSelect
            isDisabled={disableField}
            options={professions}
            placeholder="Selecione uma profissão"
            label="Profissão"
            width="200px"
            getOptionLabel={option => option.profession.name}
            getOptionValue={option => option.profession.id}
            value={
              professions.find(
                profession => profession.profession.id === selected,
              ) ?? undefined
            }
            onChange={profession => handleSelectProfession(profession)}
          />
          <Controller
            name="specialty"
            control={control}
            render={field => (
              <SearchSelect
                isDisabled={disableField || loadingSpecialty}
                options={specialties}
                isLoading={loadingSpecialty}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                placeholder="Selecione uma especialidade"
                label="Especialidade"
                width="100%"
                {...field}
                onChange={option => {
                  field.onChange(option);
                  makeReduxGetAllProfessionals().getAll({
                    filter: {
                      specialty: option.id,
                      org: selectUser.orgId,
                    },
                  });
                }}
              />
            )}
          />
        </FormRow>
        <Controller
          name="specialist"
          control={control}
          render={field => {
            console.log('field', field);
            return (
              <SearchSelect
                isDisabled={disableField}
                placeholder="Selecione um profissional"
                options={professionals.map(professional => ({
                  fullname:
                    professional.user.firstName +
                    ' ' +
                    professional.user.lastName,
                  id: professional.professional.id,
                  userId: professional.user.id,
                  avatar: professional.user.avatar,
                }))}
                getOptionLabel={option => option.fullname}
                getOptionValue={option => option.id}
                label="Profissional"
                {...field}
              />
            );
          }}
        />
      </Form>
    </SectionContainer>
  );
};
