import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import _ from 'lodash';
import CreatableSelect from 'react-select/creatable';
import { EditIcon } from '~/presentation/base/icons';
import { GetAppointmentById } from '~/domain/usecases/appointment/remote';
import { makeReduxUpdateAppointment } from '~/main/factories/usecases/appointment/UpdateAppointmentFactory';
import { makeReduxGetAllConsultant } from '~/main/factories/usecases/consultant/GetAllConsultant';
import { iStore } from '~/domain/interfaces/models';
import { makeRemoteUpdateInstantAppointment } from '~/main/factories/usecases/appointment/UpdateInstantAppointmentFactory';
import { schemaUpdateSidebar } from '~/validation/validators/appointment/UpdateAppointmentValidator';
import { makeReduxGetAllHealthUnits } from '~/main/factories/usecases/healthUnits/GetAll';
import {
  Control,
  DropdownIndicator,
  Option,
} from '~/presentation/components/filter/styles/StyledFilterAppointmentHistory';
import { Label } from '~/presentation/components/UI/select/styles';
import {
  MessageError,
  selectStyles,
} from '../../modalRequestConsultation/styles/StyledModalRequestConsultation';
import { HeaderCloseIcon } from '../styles/sidebarStyles';
import { Button } from '../../UI';
import { translator } from '../../i18n';
import Input from '../../UI/input';
import {
  Container,
  ContentHeader,
  HeaderTitle,
  Body,
  Header,
  Card,
  CardTitle,
  Data,
  NotesDiv,
  NotesCard,
  InterconsultTextArea,
} from './styles/StyledInterconsultData';
import { AlertMessage } from '../../messages/AlertMessage';
import { V4hSpin } from '../../spin';

interface iStoreParams {
  appointmentId: string;
}

interface participantsParams {
  fullNameConsultant?: string;
  fullNameProfessional?: string;
  dnv?: string;
  unit?: string;
  regValue?: string;
  professionalRegValue?: string;
  regType?: string;
  clinicalCondition?: string;
  forwarding?: string;
}

export interface ownProps {
  handleClose: () => void;
  appointment: GetAppointmentById.Model;
  loading?: boolean;
  handleUpdate?: () => void;
}

const InterconsultData: React.FC<ownProps> = ({
  appointment,
  handleClose,
  loading,
  handleUpdate,
}) => {
  const { access, selectUser } = useSelector((store: iStore) => store.auth);
  const { records: healthUnits } = useSelector(
    (store: iStore) => store.healthUnits,
  );

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isValid },
  } = useForm<participantsParams>({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaUpdateSidebar),
    defaultValues: {
      clinicalCondition: appointment?.appointment?.clinicalCondition ?? '',
      fullNameConsultant: appointment?.appointment?.info?.name ?? '',
      fullNameProfessional: appointment?.otherProfessionals?.[0]?.name ?? '',
      forwarding: appointment?.appointment?.forwarding ?? '',
      regType: appointment?.appointment?.info?.regType ?? '',
      regValue: appointment?.appointment?.info?.regValue ?? '',
      professionalRegValue:
        appointment?.otherProfessionals?.[0]?.regValue ?? '',
      unit: appointment?.healthUnit?.name ?? '',
    },
  });

  const appointmentId = useMemo(
    () => appointment?.appointment?.id,
    [appointment?.appointment?.id],
  );
  const professionalId = useMemo(
    () => appointment.otherProfessionals?.[0]?.id,
    [appointment.otherProfessionals],
  );

  const isConnected = useMemo(() => {
    return !!access.token?.length;
  }, [access]);

  const canUpdate = useMemo(() => {
    return selectUser.role !== 'CON';
  }, [access]);

  const canEditProfessional = useMemo(
    () => !appointment?.otherProfessionals?.[0]?.professional,
    [appointment?.otherProfessionals],
  );

  const handleSubmitFunc = handleSubmit(values => {
    if (isConnected) {
      makeReduxUpdateAppointment().update({
        body: {
          appointmentId: Number(appointmentId),
          info: {
            name: values?.fullNameConsultant,
            regValue: values?.regValue,
            regType: !!values?.regType?.length ? values.regType : 'CPF',
          },
          professionals: [
            {
              id: professionalId ? Number(professionalId) : undefined,
              name: values?.fullNameProfessional,
              unit: values?.unit,
              regValue: values?.professionalRegValue,
            },
          ],
          clinicalCondition: values?.clinicalCondition ?? '',
          forwarding: values?.forwarding ?? '',
          healthUnit: healthUnits?.find(unit => unit.name === values?.unit)?.id,
        },
        onSuccess: () => {
          handleUpdate?.();
        },
      });
    }

    if (!isConnected)
      makeRemoteUpdateInstantAppointment()
        .updateInstantAppointment({
          appointmentId: Number(appointmentId),
          otherProfessionalId: Number(professionalId),
          name: values?.fullNameProfessional,
          unit: values?.unit,
          regValue: values?.professionalRegValue,
          appointment: {
            clinicalCondition: values?.clinicalCondition,
            forwarding: values?.forwarding,
            healthUnit: healthUnits?.find(unit => unit.name === values?.unit)
              ?.id,
          },
          externalConsultant: {
            name: values?.fullNameConsultant,
            regValue: values?.regValue,
            regType: values?.regType,
          },
        })
        .then(() => {
          AlertMessage({
            message: 'Informações atualizadas com sucesso',
            type: 'success',
          });
          handleUpdate?.();
        });
  });

  const handleKeyPress = useCallback((e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }, []);

  const generateHealthUnitSelectOptions = useCallback(() => {
    return _.sortBy(
      healthUnits?.flatMap(value => {
        return [
          {
            value: value.id,
            label: value.name,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [healthUnits]);

  useEffect(() => {
    register('clinicalCondition');
    register('fullNameConsultant');
    register('fullNameProfessional');
    register('forwarding');
    register('regType');
    register('regValue');
    register('professionalRegValue');
    register('unit');

    makeReduxGetAllConsultant().getAll({});
    makeReduxGetAllHealthUnits().getAll({
      limit: 9999,
    });
  }, [register]);

  useEffect(() => {
    if (appointment) {
      setValue(
        'clinicalCondition',
        appointment?.appointment?.clinicalCondition ?? '',
        { shouldValidate: true },
      );
      setValue(
        'fullNameConsultant',
        appointment?.appointment?.info?.name ?? '',
        {
          shouldValidate: true,
        },
      );
      setValue(
        'fullNameProfessional',
        appointment?.otherProfessionals?.[0]?.name ?? '',
        { shouldValidate: true },
      );
      setValue('forwarding', appointment?.appointment?.forwarding ?? '', {
        shouldValidate: true,
      });
      setValue('regType', appointment?.appointment?.info?.regType ?? '', {
        shouldValidate: true,
      });
      setValue('regValue', appointment?.appointment?.info?.regValue ?? '', {
        shouldValidate: true,
      });
      setValue(
        'professionalRegValue',
        appointment?.otherProfessionals?.[0]?.regValue ?? '',
        { shouldValidate: true },
      );
      setValue('unit', appointment?.healthUnit?.name ?? '', {
        shouldValidate: true,
      });
    }
  }, [appointment, setValue]);

  return (
    <Container>
      <ContentHeader>
        <HeaderTitle>Informações</HeaderTitle>
        {!loading && <HeaderCloseIcon onClick={handleClose} />}
      </ContentHeader>
      <Body onSubmit={handleSubmitFunc}>
        {loading ? (
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignContent: 'center',
              justifyContent: 'center',
            }}
          >
            <V4hSpin background="white" />
          </div>
        ) : (
          <>
            <Card>
              <CardTitle>{translator('Dados do paciente')}</CardTitle>
              <div className="gap-div">
                <Controller
                  control={control}
                  name="fullNameConsultant"
                  render={({ value, onChange }) => (
                    <Input
                      id="input_interconsultData"
                      name="fullNameConsultant"
                      label="Nome do paciente:"
                      placeholder="Insira o nome do paciente"
                      value={value}
                      onChange={onChange}
                      error={Boolean(errors.fullNameConsultant)}
                      autoFocus
                      message={errors?.fullNameConsultant?.message}
                      disabled={!canUpdate}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="regValue"
                  render={({ value, onChange }) => (
                    <Input
                      id="input_interconsultData"
                      name="regValue"
                      label={`${appointment?.appointment?.info?.regType ?? 'CPF'}`}
                      placeholder={`Insira o ${appointment?.appointment?.info?.regType ?? 'CPF'} do paciente`}
                      value={value}
                      onChange={onChange}
                      error={Boolean(errors.regValue)}
                      message={errors?.regValue?.message}
                      disabled={!canUpdate}
                    />
                  )}
                />
              </div>
            </Card>
            <Card>
              <CardTitle>{translator('Dados do profissional')}</CardTitle>
              <div className="gap-div">
                <Controller
                  control={control}
                  name="fullNameProfessional"
                  render={({ value, onChange }) => (
                    <Input
                      id="input_interconsultData"
                      name="fullNameProfessional"
                      label="Nome do profissional:"
                      placeholder="Insira o nome do profissional"
                      value={value}
                      onChange={onChange}
                      error={Boolean(errors.fullNameProfessional)}
                      autoFocus
                      message={errors?.fullNameProfessional?.message}
                      disabled={!canEditProfessional || !canUpdate}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="unit"
                  render={({ value, onChange }) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}
                    >
                      <Label style={{ marginBottom: '6px' }}>Unidade:</Label>
                      <CreatableSelect
                        className="select"
                        components={{
                          Control,
                          DropdownIndicator,
                          IndicatorSeparator: () => null,
                          Option,
                        }}
                        controlShouldRenderValue
                        placeholder="Insira a Unidade do profissional"
                        formatCreateLabel={(label: string) =>
                          `Buscar por ${label}`
                        }
                        options={generateHealthUnitSelectOptions()}
                        value={generateHealthUnitSelectOptions().find(
                          option => option.label === value,
                        )}
                        onChange={e => {
                          onChange(e?.label, { shouldValidate: true });
                        }}
                        styles={selectStyles()}
                        isValidNewOption={() => false}
                        noOptionsMessage={() => 'Nenhum resultado encontrado'}
                        isDisabled={!canEditProfessional || !canUpdate}
                      />
                    </div>
                  )}
                />

                <Controller
                  control={control}
                  name="professionalRegValue"
                  render={({ value, onChange }) => (
                    <Input
                      id="input_interconsultData"
                      name="professionalRegValue"
                      label="Registro:"
                      placeholder="Insira o Registro do profissional"
                      value={value}
                      onChange={onChange}
                      error={Boolean(errors.professionalRegValue)}
                      message={errors?.professionalRegValue?.message}
                      disabled={!canEditProfessional || !canUpdate}
                    />
                  )}
                />
              </div>
            </Card>

            <NotesDiv>
              <Header>Quadro clínico</Header>
              <NotesCard
                style={{
                  border: errors.clinicalCondition && '1px solid red',
                  width: '100%',
                }}
              >
                <Controller
                  control={control}
                  name="clinicalCondition"
                  render={({ value, onChange }) => (
                    <>
                      <InterconsultTextArea
                        value={value}
                        onChange={onChange}
                        disabled={!canUpdate}
                      />
                    </>
                  )}
                />
              </NotesCard>
              {errors?.clinicalCondition && (
                <MessageError>
                  {errors?.clinicalCondition?.message}
                </MessageError>
              )}
              <Header>Encaminhamento do atendimento</Header>
              <NotesCard
                style={{
                  border: errors.forwarding && '1px solid red',
                  width: '100%',
                }}
              >
                <Controller
                  control={control}
                  name="forwarding"
                  render={({ value, onChange }) => (
                    <InterconsultTextArea
                      value={value}
                      onChange={onChange}
                      disabled={!canUpdate}
                    />
                  )}
                />
              </NotesCard>
              {errors?.forwarding && (
                <MessageError>{errors?.forwarding?.message}</MessageError>
              )}
            </NotesDiv>
            {canUpdate && (
              <div className="send-data">
                <Button
                  type="submit"
                  variant="primary"
                  onKeyPress={e => handleKeyPress(e)}
                  disabled={!isValid}
                >
                  Enviar
                </Button>
              </div>
            )}
          </>
        )}
      </Body>
    </Container>
  );
};

export default InterconsultData;
