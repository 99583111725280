import { Typography, theme } from '@wisecare/design-system-web';
import styled from 'styled-components';

export const Container = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
`;

export const SectionsContainer = styled.main`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  box-sizing: content-box;
`;

export const Content = styled.div`
  width: 100%;
  height: fit-content;
  max-width: 50.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  border: 1px solid #dedede;
  border-radius: 0.5rem;
  background-color: ${theme.colors.white.DEFAULT};
`;

export const BodyContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
`;

export const Footer = styled.footer`
  width: 100%;
  position: sticky;
  bottom: 0;

  display: flex;
  justify-content: center;

  background-color: ${theme.colors.white.DEFAULT};
  border-top: 1px solid #dedede;
  padding: 1rem 4.5rem;
`;

export const FooterContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonsContainerSecondary = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;

export const FormRow = styled.div<{ gap?: string }>`
  width: 100%;
  display: flex;
  gap: ${({ gap }) => gap || '1.5rem'};
`;

export const Form = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 1.5rem;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > text {
    margin: 0;
  }
`;

export const SectionContainer = styled.section`
  width: 100%;
  padding: 1.5rem;

  display: flex;
  flex-direction: column;

  border-top: 1px solid #dedede;
`;

export const SectionLabel = styled(Typography).attrs({
  variant: 't2_16bold',
})`
  font-size: 1rem;
  margin-bottom: 1.5rem;
`;

export const Subheader = styled.header`
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;

  & > text {
    color: ${theme.colors.black[4]};
  }
`;

export const ContainerFiles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
  overflow-y: auto;
`;

interface iDropzone {
  status?: 'success' | 'error' | 'default';
}

export const Dropzone = styled.div<iDropzone>`
  width: 100%;
  max-width: 886px;
  padding: 16px 24px;
  border: ${({ status }) => {
    switch (status) {
      case 'success':
        return '2px dashed #00b341';
      case 'error':
        return '2px dashed #f00';
      default:
        return '2px dashed #B4B7B9';
    }
  }};

  border-radius: 8px;
  background-color: #ffffff;
`;

export const ContentFiles = styled.div`
  display: flex;
  gap: 24px;
`;

export const Message = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  span {
    font-size: 16px;
    color: #0565d9;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
  }
`;

export const FileList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FileSize = styled.div`
  display: flex;
  gap: 8px;

  p {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #656a6e;
  }

  strong {
    color: #656a6e;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
  }
`;

export const FilesFormats = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #656a6e;
  }

  strong {
    color: #656a6e;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
  }
`;
