import React, { useCallback, useState, useEffect } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import {
  SortButton,
  Button,
  Icon,
  Checkbox,
  IconButton,
  theme,
  Typography,
} from '@wisecare/design-system-web';
import _ from 'lodash';
import SearchSelect from '../UI/searchSelect';
import { useFormContext } from 'react-hook-form';
import { FormData as FormDataForm } from './CreateReport';
import { useDropzone } from 'react-dropzone';
import {
  Dropzone,
  Content,
  Message,
  FileList,
  FileSize,
  FilesFormats,
  Container,
  TableContainer,
  DeleteFiles,
} from './styles/StyledFiles';
import { UploadFiles } from '~/presentation/base/icons';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@wisecare/design-system-web';
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { makeRemoteSendFilesDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/SendFilesDiagnosisReportFactory';
import { AlertMessage } from '../messages/AlertMessage';
import { makeReduxListExamTypesDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/ListExamTypesDiagnosisReportFactory';
import { iStore } from '~/domain/interfaces/models';
import { useSelector } from 'react-redux';

interface iFiles {
  id: number;
  filename: string;
  examType?: number;
  size?: number;
}

interface iStatus {
  status: 'success' | 'error' | 'default';
}

const Files = () => {
  const [dropStatus, setDropStatus] = useState<iStatus['status']>('default');

  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState<Record<number, boolean>>({});

  const { records } = useSelector(
    (store: iStore) => store.diagnosisReport.examTypes,
  );

  const { register, setValue, getValues, watch } =
    useFormContext<FormDataForm>();

  const generateExamOptions = () => {
    return _.sortBy(
      records?.map(option => ({
        label: option.descr,
        value: option.id,
      })),
      option => option?.label?.replace(/\s/g, '').toLocaleLowerCase(),
    );
  };

  const examOptionSelected = (index: number) => {
    return generateExamOptions().find(
      option => option.value === getValues().files[index]?.examType,
    );
  };

  const columns: ColumnDef<iFiles>[] = [
    {
      id: 'checkbox',
      accessorKey: 'checkbox',
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && 'indeterminate')
          }
          onCheckedChange={e => {
            table.toggleAllRowsSelected(!!e);
          }}
          aria-label="Selecionar todos os arquivos"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={e => {
            row.toggleSelected(!!e);
          }}
          aria-label="Selecionar arquivo"
        />
      ),
    },
    {
      accessorKey: 'filename',
      header: ({ column }) => (
        <SortButton column={column}>Nome do arquivo</SortButton>
      ),
    },

    {
      accessorKey: 'examType',
      header: ({ column }) => (
        <SortButton column={column}>Tipos de exame</SortButton>
      ),
      cell: ({ row }) => (
        <SearchSelect
          options={generateExamOptions()}
          value={examOptionSelected(row.index)}
          onChange={value => {
            const files = watch('files') ?? [];

            const updatedFiles = files?.map((file, index) => {
              if (index === row.index) {
                return { ...file, examType: Number(value?.value) };
              }
              return file;
            });
            setValue('files', updatedFiles);
          }}
        />
      ),
    },

    {
      accessorKey: 'size',
      header: ({ column }) => <SortButton column={column}>Tamanho</SortButton>,
      cell: ({ row }) => {
        const size = row.getValue('size');
        const sizeInMb = Number(size) / 1024 / 1024;
        return sizeInMb ? `${sizeInMb.toFixed(2)}mb` : '';
      },
    },
    {
      id: 'actions',
      cell: ({ row }) => (
        <IconButton
          icon="delete"
          color="red-7"
          size="sm"
          type="button"
          variant="destructive"
          onClick={() => {
            const files = getValues().files ?? [];

            const deletedfiles = files?.filter(
              (item, index) => index !== row.index,
            );

            setValue('files', deletedfiles);
          }}
        />
      ),
    },
  ];

  const table = useReactTable({
    data: getValues().files ?? [],
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      rowSelection,
    },
  });

  const onDrop = useCallback(acceptedFiles => {
    register('files');

    const formData = new FormData();

    acceptedFiles?.forEach((file: File) => {
      formData.append('files', file);
    });

    makeRemoteSendFilesDiagnosisReport()
      .send({
        body: formData,
      })
      .then(res => {
        const files = getValues().files ?? [];
        setDropStatus('success');

        setValue('files', [...files, ...res.files]);
      })
      .catch(() => {
        setDropStatus('error');
        AlertMessage({
          type: 'danger',
          message: 'Erro ao enviar arquivo(s).',
        });
      });

    setTimeout(() => {
      setDropStatus('default');
    }, 2000);
  }, []);

  const onDropRejected = useCallback(rejectedFiles => {
    setDropStatus('error');
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: [
      'image/jpeg',
      'image/png',
      'image/gif',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
      'application/vnd.oasis.opendocument.text',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'audio/mpeg',
      'audio/mpa',
      'audio/wav',
      'video/mp4',
      'video/x-m4v',
      'video/x-ms-wmv',
      'video/x-msvideo',
      'video/mpeg',
      'application/zip',
      '.jpg',
      '.jpeg',
      '.png',
      '.gif',
      '.pdf',
      '.doc',
      '.dcm',
      '.docx',
      '.ppt',
      '.pptx',
      '.pps',
      '.ppsx',
      '.odt',
      '.xls',
      '.xlsx',
      '.mp3',
      '.mpa',
      '.wav',
      '.mp4',
      '.m4v',
      '.wmv',
      '.avi',
      '.mpg',
      '.zip',
    ],
    maxFiles: 90,
    multiple: true,
    validator(file) {
      if (file.size > 80000000) {
        return {
          code: 'file-too-large',
          message: 'Arquivo muito grande. O tamanho máximo é 80mb.',
        };
      }
      return null;
    },
  });

  useEffect(() => {
    makeReduxListExamTypesDiagnosisReport().listExamTypes({});
  }, []);

  return (
    <Container>
      <Dropzone {...getRootProps({ refKey: 'innerRef' })} status={dropStatus}>
        <input {...getInputProps()} />
        <Content>
          <Message>
            <UploadFiles />
            <span>Clique para adicionar os arquivos ou arraste-os aqui.</span>
          </Message>
          <FileList>
            <FileSize>
              <p>Tamanho máximo:</p>
              <strong>80mb por arquivo</strong>
            </FileSize>
            <FilesFormats>
              <p>É possível adicionar os seguintes formatos: </p>
              <strong>
                .jpg .jpeg. .png .gif .pdf .doc .dcm .docx .ppt .pptx .pps .ppsx
                .odt .xls .xlsx .mp3 .mpa .wav .mp4 .m4v .wmv .avi .mpg .zip
              </strong>
            </FilesFormats>
          </FileList>
        </Content>
      </Dropzone>

      <TableContainer>
        <DeleteFiles>
          <Typography variant="b3_14medium">{`${Object.keys(rowSelection).length} arquivos selecionados`}</Typography>
          <Button
            variant="textDestructive"
            type="button"
            onClick={() => {
              const files = watch('files') ?? [];

              const deletedfiles = files?.filter(
                (item, index) => !rowSelection[index],
              );
              setValue('files', deletedfiles);
            }}
          >
            Excluir arquivos
          </Button>
        </DeleteFiles>
        <Table rounded>
          <TableHeader>
            {table.getHeaderGroups().map(headerGroup => (
              <>
                {headerGroup.headers.map(header => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                ))}
              </>
            ))}
          </TableHeader>
          <TableBody style={{ backgroundColor: '#ffff' }}>
            {table.getRowModel().rows.map(row => (
              <TableRow key={row.id}>
                {row.getAllCells().map(cell => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Files;
